import React from "react"
import { Link } from "gatsby"

import { MdSend } from "react-icons/md"
import { css } from "@emotion/react"

const ContactThumb = () => {

    return (
        <div css={main}>
            <Link to="#contact"><MdSend /></Link>
        </div>
    )
}

export default ContactThumb

const main = css`
  position: fixed;
  left: 0;
  bottom: 40px;
  z-index: 100;
  background-color: #ff4208;
  background-image: linear-gradient(180deg,#ff4208 0%,#ff07a8 100%);;
  padding: 20px 20px 20px 15px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  font-size: 30px;
  box-shadow: 0px 2px 18px 10px rgba(50,50,93,0.06);
  border-left: none;

  svg {
      margin-bottom: -2px;
  }
  
  a {
    color: white;
    text-decoration: none;
  }
`