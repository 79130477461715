import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import Header from "../components/header"
import Product from "../components/product"
import SeO from "../components/seo"

import { css } from "@emotion/react"
import Form from "../components/form"
import ContactThumb from "../components/contactThumb"
import Embed from "../components/embed"
import Quotes from "../components/quotes"

const CategoryTemplate = (props) => {
    const lang = props.pageContext.lang
    const category = props.data.category
    const title = category.data.title.text
    const desc = category.data.desc
    const subtitle = category.data.subtitle.text
    const embed = category.data.video && category.data.video.html
    const rich_text = category.data.rich_text.html
    const clients_img = props.data.homepage.data.clients_image
    // const thumb_image = category.data.image

    const products = props.data.products.edges.map( edge => edge.node )

    // console.log(props)

    return (
        <Layout>
            <SeO 
                title={`${title} - Lyketil Agence Digitale Créative à Lausanne`}
                description={desc}
                lang={lang}
                slug={props.uri}
            />

            <Header 
                title={`${title} en Suisse romande`}
                subtitle={subtitle}
                intro={desc}
                clients_img={clients_img.fluid}
                lang={lang}
                // indent={thumb_image.fluid ? true : false}
            />

            {embed && <section>
                <div className="container">
                    <Embed embed={embed} />    
                </div> 
            </section>}

            <section css={productsList}>
                <div className="container">
                    {products.map( product => (
                        <Product 
                            key={product.uid}
                            uid={product.uid}
                            title={product.data.title.text}
                            desc={product.data.desc}
                            subtitle={product.data.subtitle.text}
                            image={product.data.icon}
                            bg={`white`}
                        />
                    ))}
                </div>
            </section>

            <section>
                <div className="container">
                    <Form 
                        formName={title}
                    />
                </div>
            </section>

            <section>
                <Quotes category={props.pageContext.slug} />
            </section>

            {rich_text && <section css={rich}>
                <div className="container">
                    <h2 className="alt-font">Questions fréquentes</h2>
                    <div dangerouslySetInnerHTML={{ __html: rich_text }} />
                </div> 
            </section>}

            <ContactThumb />
        </Layout>
    )
}

export default CategoryTemplate

const productsList = css`
    padding-top: 50px; 
    padding-bottom: 50px; 
`

const rich = css`
    margin-top: 150px;
    margin-bottom: 66px;
    color: white;

    p {
        opacity: .88;
    }
`

export const categoryTemplateQuery = graphql`
    query CategoryTemplateQuery($slug: String!) {
        category: prismicCategories(uid: {eq: $slug}) {
            uid
            data {
                title {
                    text
                }
                subtitle {
                    text
                }
                desc
                video {
                    html
                }
                rich_text {
                    html
                }
            }
        }
        products: allPrismicProducts(filter: {data: {category: {uid: {eq: $slug}}}}) {
            edges {
              node {
                uid
                data {
                  title {
                    text
                  }
                  subtitle {
                    text
                  }
                  desc
                  icon {
                    fluid(imgixParams: {q: 100}, maxWidth: 500) {
                        ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
        }
        homepage: prismicHomepage {
            data {
              clients_image {
                fluid(maxWidth: 700) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
    }
`