import React from 'react'

import { css } from "@emotion/react"

const Embed = (props) => {

    return (
        <div css={videoWrapper}>
            <div dangerouslySetInnerHTML={{ __html: props.embed }} />
        </div>
    )
}

export default Embed

const videoWrapper = css`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe, 
    object, 
    embed { 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`